@import "styles/varibles.scss";

.sui-facet {
  // margin-top: 32px;
  background-color: $facet_background;
  padding: 20px;
}
.sui-facet__title {
  text-transform: capitalize;
  font-size: 15px;
  color: $facet_heading;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 0;
}
.sui-multi-checkbox-facet {
  color: $facet_heading;
  font-size: 13px;
  margin: 8px 0;
  font-weight: 500;
}
.side_heading {
  font-size: 20px;
  color: $home_body_clr;
  font-family: arial;
  text-transform: uppercase;
}
.clearFilter {
  color: #006bb8;
  cursor: pointer;
  float: right;
  font-weight: 500;
  margin-top: 6px;
}

.tooltip-arrow, .tooltip-inner{
  opacity: .7;
}
.sui-facet_tree {
  // margin-top: 32px;
  background-color: $facet_background;
}
.newFacet {
  margin-top: -55px !important;
}
.procedurs_div {
 margin-top: inherit;
  margin-left: 3%;
  padding-bottom: 10%;
}
.procedurs_span {
  color: black;
  font-size: 13px;
  margin: 8px 7px;
  font-weight: 500;
}
.clearFilter{
  color: #006bb8;
    cursor: pointer;
    float: right;
    font-weight: 500;
    margin-top: 6px;
}
.sui-multi-checkbox-facet__option-input-wrapper{
  display: flex;
  align-items: baseline;

}
.countDisplay{
  float: right;

  /* padding: 1%; */
  margin-right: 8%;
  color: #888888;
  font-size: 0.85em;
  margin-left: 24px;
}
.css-1aznpnh-MuiSlider-root ,.css-9cct2k{
  color: black !important;
  width: 90% !important;
  margin-left: 4% !important;
  height: 2% !important;
}
.css-eg0mwd-MuiSlider-thumb,.css-7drnjp {
  height: 13px !important;
  width: 13px !important;
}

.kwDiv{
     /* margin-left: 7%; */
    /* padding-bottom: 10%; */
    margin-top: -11%;
    /* margin-left: 9%; */
    margin-bottom: 6%;
    padding: 0px 2px 9px 20px;
    word-break: break-word;
}
.countDisplayKW{
  margin-right: 8%;
  color: #888888;
  font-size: 0.85em;
  margin-left: 12px;
  float: right;
}

