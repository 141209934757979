@import "styles/varibles.scss";

.feedback-header {
    background-color: #def0e4;
    // min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // font-size: calc(10px + 2vmin);
    // color: white;
}
.feedback-padding{
    padding: 20px 56px 0px;
}