@import "styles/varibles.scss";

.selectable {
  cursor: pointer;
  color: $home_body_clr;
  font-size: 16px;
  text-transform: initial;
}
.heading_div {
  font-size: 21px;
  span {
    padding: 10px;
    font-size: 19px;
    font-weight: bold;
    text-transform: capitalize;
  }
}
.fa {
  margin-right: 3%;
}
.fa-square-o {
  color: #00617f;
}
.fa-check-square {
  color: #0091df;
}
.parent_div {
  background-color: #ecf6e2;
  padding: 20px;
  margin-bottom: 5%;
}
.divform {
    background-color: #d9effa;
    padding: 20px;
}
.innerdiv{
  margin: 22px -80px 12px 37px;
  width: 97%;
}
.innerdiv form {
    display: inline-block;
    width: 80%;
}
.filters{
  font-size: 20px;
  color: #10384f;
  font-family: arial;
  text-transform: uppercase;
}
.clearFilter {
  color: #006bb8;
  cursor: pointer;
  float: right;
  font-weight: 500;
  margin-top: 6px;
}
.input_form_auto{
  width: 60%;
  height: 50px;
  border: none;
  padding-left: 10px;
}
.btn_button_btn:hover {
  background: $btn_hover_clr;
}
.search_icon_ {
  font-size: 22px;
  margin-top: -3px;
  vertical-align: bottom !important;
}
.btn_button_btn{
  width: 5%;
  height: 48px;
  background-color: #0091df;
  border-radius: 0 10px 10px 0;
  color: #fff;
  position: absolute;
}
 .ant-select-selector{
  padding: 4% 1% 2% 1% !important;
    line-height: 28px !important;
    border-radius: 0px !important;
}
.my_saved_search_searchPage{
  margin-left: 80px !important;
    /* margin-bottom: -23px !important; */
    font-weight: 500;
    margin-top: 1% !important;
    font-size: 16px;
    color: #10384f !important;
    position: absolute;
}
.iconClass{
  margin: auto;
  padding: 14px;
}

.loader {
  height: 100vh;
  width: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}
.loader .spinner-border {
  position: absolute;
  top: 40%;
  left: 50%;
}