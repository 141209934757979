/* Colors - START  */
$background: #a5db9c8a;
$headerbackgroundClr: #00808024;
$Appheaderh1: grey;
$Appheader2: #00617f;
$titlecolor: #0090c5;
$appheaderbgClr: #2bafe224;
$tabBottombar: #e1f4fb;
$tabSeletedbottomclr: #6bc200;
$tabnotseletedclr: #526f80;
$black: #000;
$homeheaderBg3: #00617f;
$clrWhite: #ffffff;
$pure_white: #ffffff;
$header_blue: #015488;
$modal_index: 1300;
$loader_index: $modal_index + 1;
$facet_heading: black;
$facet_background: #ecf6e2;
$row_baseline: #0d6efd59;
$heightllight_clr: #ffff008c;
$a_tag_clr: #0544a3;
$layout_bottomp: #eeeeee;
$loader_clr: #3a56e4;
$loader_background: #0077cc03;
$home_heading_bottom: #c1d9e0;
$home_body_clr: #10384f;
$home_heading_clr: #66b512;
$home_div_clr: #ecf6e2;
$home_seconddiv_heading: #0091df;
$tab_active_clr:#66b512;
$tab_not_active_clr:#10384f;
$footer_background:#80808029;
$form_background:#d9effa;
$btn_hover_clr:#028dd8;
/* Colors-End */
