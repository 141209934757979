@import "styles/varibles.scss";

.home-body-content{
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px 56px 0px;
}

.col1 {
  font-family: Arial, Helvetica, sans-serif !important;
  //margin-top: 2%;
  justify-content: center;
  margin-left:-18px;
// margin-bottom: 2%;
  .col1_heading {
    font-size: 18px;
    color: $home_body_clr;
    font-weight: regular;
    text-transform: uppercase;
    font-family: Arial;
  }
  .heading1_div {
    padding-bottom: 2%;
    border-bottom: 1px solid $home_heading_bottom;
  }
  .col_sub1 {
    width: 40%;
    margin-right: 8px;
  }
  .col_sub2 {
    width: 20px;
  }
  .col_sub3 {
    width: 22%;
    margin-right: 8px;
  }
  .col_sub4 {
    width: 26%;
  }
  .col1_a {
    text-align: right;
  }
  .col1_a a{
    cursor: pointer;
  }
  .content_cls {
    border-bottom: 1px solid $home_heading_bottom;
  }
  .date_cls {
    font-size: 14px;
    color: $home_body_clr;
    font-weight: normal;
    margin-bottom: 1%;
    margin-top: 2.5%;
  }
  .heading_cls {
    font-size: 24px;
    line-height: 30px;
    color: $home_heading_clr;
    font-weight: regular;
    font-style: italic;
    margin-bottom: 2.5%;
    margin-left: -0.5%;
  }
  .body_cls {
    font-size: 16px;
    line-height: 22px;
    color: $home_body_clr;
    font-weight: regular;
    margin-bottom: 2.5%;
  }
  .col_sub3_div {
    background-color: $home_div_clr;
    height: 100%;
  }
  .heading2 {
    font-size: 24px;
    color: $home_seconddiv_heading;
    font-weight: bold;
    font-style: italic;
    margin-bottom: 15px;
    font-family: Arial;
  }
  .innerDiv {
    padding: 20px;
  }
  .img_cls {
    height: 100%;
    width: 100%;
  }
  .sub_heading {
    font-size: 16px;
    color: $home_heading_clr;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  .cls2 {
    margin-top: 20px;
  }
  .childDivs {
    width: 100%;
    height: 65px;
    margin-bottom: 16px;
    border: 1px solid $home_heading_bottom;
    padding: 10px;
  }
  .childDivp {
    font-size: 17px;
    font-family: arial;
    color: $home_body_clr;
    font-weight: lighter;
    display: table;
  }
  .p_tag {
    // vertical-align: middle;
    // display: table-cell;
    color: $home_body_clr;
    
    margin-top: 8px;

  }
  


  

}
.newsBodyModal{
  height: 500px;
  overflow:auto;
}
.newsModal .modal-dialog{
  max-width: 90% !important;
  // width: 100%;
}