@import "styles/varibles.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sui-result__title {
  color: $titlecolor;
}
// .sui-layout-header{
//     background-color: $appheaderbgClr;
// }

.sui-layout-header {
  background-color: $pure_white;
  margin-left: -12px;
    margin-right: -10px;
}
body{
  height:auto;
}

#app div{
  height:100%
}
#root {
  height: 100%;
}
html{
  background-color: white !important;
  height: 100% !important;
}

.font-family-arial{
  font-family: Arial !important;
}

.loader {
  height: 100vh;
  width: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}
.loader .spinner-border {
  position: absolute;
  top: 40%;
  left: 50%;
}