@import 'styles//varibles.scss';

#savedSearch {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#savedSearch td,
#savedSearch th {
  border: 1px solid #ddd;
  padding: 8px;
}

#savedSearch tr:nth-child(even) {
  background-color: #f2f2f2;
}

#savedSearch tr:hover {
  background-color: #ddd;
}

#savedSearch th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: darkgray;
  color: white;
}

.bodyPadding {
  padding: 20px 56px;
}

.center-SavedSearch {
  margin: 0px auto;
}

.savedSearchTitle {
  color: #006bb8 !important;
  cursor: pointer;
  font-weight: 500;
}

.savedSearchDelete {
  color: red;
  font-weight: 500;
  cursor: pointer;
}

.UserName {
    font-size: 16px;
    font-weight: 500;
    margin-left: 15px;
    margin-bottom: 14px;
}