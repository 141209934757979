.global-ef table,.global-ef th,.global-ef td {
    border: 1px solid black !important;
    border-collapse: collapse;
  }
  .global-ef-title {    
    margin-bottom: 10px !important;
  }
  
//   style="
//   margin-bottom: 18px;
// "