@import "../../styles/varibles.scss";
.appHeader {
  .headerRow{
    height: 86px;    
  }
  .header_first {
    color: $Appheaderh1;
    font-weight: bold;
    display: block;
    line-height: 0.6 !important;
    font-size: 32px;
  }
  .header2 {
    font-style: italic;
    top: 65px;
    left: 59px;
    position: absolute;
    color: $Appheader2;
    font-size: 18px;
  }
  .headeimgCls {
    margin-left: 65%;
    display: block;
    max-width: 184px;
    max-height: 64px;
  }
  .header_alignment{
    margin-left: -10px;
  }
  hr{
    width: 102%;
    margin-left: -11px;
    color: $home_heading_bottom;
  }
}
