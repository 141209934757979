@import "styles/varibles.scss";
 .footer_class{
    background-color: $home_div_clr;
    height: 57px;
    align-items: center;
    font-size: 14px;
    color: $home_body_clr;
    font-family:Arial, Helvetica Neue, sans-serif;
 }
 .footer_continer{
    margin-top: auto;
    /* display: unset; */
    // position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    padding-left: 0;
    padding-right: 0;
 }
 .footer_section_first{
   a{
    color: $home_body_clr !important;
   }
    margin-left:48px;
 }
 .copyright{
    font-size: 12px;
 }
 .footer_span{
    font-weight: 600;
 }
 .hrefColor a {
   color: $home_body_clr !important;
 }