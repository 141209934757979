@import "styles/varibles.scss";

 .btn_button {
  background-color: $home_seconddiv_heading;
  color: $pure_white;
  border-radius: 0px 10px 10px 0px;
  width: 5%;
  }
  .home_form{
    justify-content: center;
  }
  .div_form {
    justify-content: center;
    padding: 2% !important;
    display: flex;
    background-color: $form_background;
    margin-left: -12px;
    margin-right: -10px;
  }
  .div_form_ {        
    background-color: #d9effa;
    /* display: flex; */
    margin-left: -12px;
    margin-right: -10px;
    height: 124px;
  }
  .input_form{
    width: 60%;
    height: 50px;
    border: none;
    padding-left: 10px;
    margin-left: -16%;
  }
  .input_form_{
    border: 1px solid #ccc;
    height: 50px;
    margin-left: 57px;
    padding-left: 10px;
    width: 82%;
    margin-top: 24px;
    border-radius: 4px 0 0 4px !important;
    
  }
  .btn_button:hover {
    // background: $btn_hover_clr;
    box-shadow: rgb(0 0 0 / 30%) 0px 0px 0px 1px inset, rgb(59 69 79 / 30%) 0px 2px 4px;
    background: linear-gradient(#3cabff, #4063f0) #3d84f7;
  }
  .search_icon {
    font-size: 22px;
    margin-top: -3px;
    vertical-align: bottom !important;
  }
  .my_saved_search_home{
    margin-left: 30px;
    font-weight: 500;
    margin-top: 1%;
    font-size: 16px;
    // color: black;
    color: $home_body_clr !important;
  }
  .span_cls{
    vertical-align: text-bottom;
  }
  
  .search-box-home{
    display: inline-block;
  }
  .saved-search-link-home{
    display: inline-block;
    margin-top:6px;
  }