@import "../../styles/varibles.scss";
.css-1hjld2u-euiTabs-m-bottomBorder {
  box-shadow: inset 0 -1px 0 $tabBottombar !important;
  height: 57px;
}
.css-1dnscl1-euiTab-selected {
  color: $black !important;
  background-color: transparent;
  box-shadow: none !important;
}
.css-wcs2c7-euiTab__content-m {
  font-weight: 500 !important;
  color: $tab_not_active_clr !important;
}
.css-10h4lu2-euiTab__content-m-selected {
  font-weight: 500 !important;
  color: $tab_active_clr !important;
}
.tabclass{
  max-height: 57px;
    height: 57px;
    margin-left: -3px;
}
.tabclass .euiTab__content{
  font-size: 17px;
}
