@import "styles/varibles.scss";

.rowcontiner {
  padding: 5px;
  border-bottom: 1px solid $row_baseline;
}
.titleCls {
  margin-top: 7px;
  vertical-align: middle;
  font-size: 14px;
  color: $home_body_clr;
  display: inline-grid;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  a {
    color: $home_body_clr;
  }
}
.titleCls a{
cursor: pointer;
}
.dateCls {
  vertical-align: middle;
  text-align: center;
  align-items: unset;
  display: inline-grid;
  align-items: center;
  font-size: 14px;
  color: $home_body_clr;

}
.iconCls{
  cursor: pointer;
  font-size: 14px;
    align-items: middle;
    align-items: center;
    display: inline-grid;
    align-items: center;
    background: url("/assets/images/Icon-Info.png") no-repeat;
    background-position: left
}
.sui-result {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  /* list-style: none; */
  // padding: 24px 0;
  /* text-decoration: none; */
  /* border: 1px solid #f0f0f0; */
  /* background: white; */
  /* border-radius: 4px; */
  /* box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 10%); */
  overflow-wrap: break-word;
  overflow: hidden;
}
.img_cls {
  // width: 70%;
  vertical-align: middle;
}
em {
  font-style: normal !important;
  background-color: $heightllight_clr;
}
.show-on-hover {
  display: none;
}
// .iconCls:hover .show-on-hover {
//   display: block;
// }

// .iconCls:hover .hide-on-hover {
//   display: none;
// }

.iconCls:hover {
  background: url("/assets/images/Icon-Info-Hover.png") no-repeat;
  background-position: left;
}

.searchDetailModal{
  font-family: Arial !important;
}


.searchDetailModal .modal-body{
  background-color: #f0f9fd;  
  border-radius: 10px;
}

.searchDetailModal table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.searchDetailModal td,.searchDetailModal th {
  border: 6px solid #f0f9fd !important;
  text-align: left;
  padding: 8px;
}

// tr:nth-child(even) {
//   background-color: #dddddd;
// }

.searchDetailModal table td{
  padding: 5px;
  font-size: 12px;
  // width: 50%;
}

.searchDetailModal table td button {
  padding: 0px 0px 0px 0px;
  font-size: 12px;
  height: 20px;
  width: 80px;
  // width: 50%;
}

.searchDetailModal table td:nth-child(1){  
  width: 180px;
}

.searchDetailModal .modal-body p{  
  font-size: 18px;
  font-family: sans-serif;
}

.searchDetailModal td:nth-child(2){
  background-color: #d9effa;
}

.searchDetailModal hr {
  margin: 6px !important;
}

.searchDetailModal .modal-dialog{
  max-width: 60% !important;
  // width: 100%;
}

.img_clsrow { 
  width: 33px; 
  height: 33px; 
  vertical-align: middle;
}

.pageInfoHeader{
  display: inline-block;width: 14%;
}
.sortByHeader{
  display: inline-block;
  width: 23%;
  margin-left: 0px;
  font-size: 16px;
  font-family: Arial;
}
.pageSizeHeader{
  display: inline-block;
    width: 9%;
    margin-left: 10px;
}
.copySearchLinkHeader{
  display: inline-block;
    margin-left: 22px;
    width: 13%;
}
.sui-results-per-page__label{
  font-family: Arial;
  font-size: 14px;
}
.sui-results-per-page{
  width: 108px;
}
.sui-paging-info{
  font-weight: 500;
  font-size: 14px;
  font-family: Arial;
}