@import "styles/varibles.scss";

.main_continer{

    .list_style{
        list-style: inside;
    }
    
    .div_ele{
        margin-bottom: 1%;
        p{
            margin-bottom: .5%;
            a{
                color: $home_body_clr;
            }
        }
        .p_links a{
            color: #006bb8;
        }
    }
}
.aboutims-body{
    font-size: 16px;
    line-height: 22px;
    color: #10384f;
}