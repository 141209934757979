@import "styles/varibles.scss";

.header_logo {
  width: 194px;
  height: 100px;
  margin: -22px;
}
.search_box_div {
  width: 80%;
  /* padding: 5px; */
  margin-bottom: 12px;
  margin-top: 22px;
  margin-left: 37px;
  margin-right: -80px;
}
.div_block {
  padding: 20px;
  background-color: $form_background;
}
.search_box_div form {  
    display: inline-block;
    width: 80%;
}
.my_saved_search {
  margin-left: 30px;
    font-weight: 500;
}
.my_saved_search_home{
  margin-left: 30px;
  font-weight: 500;
  margin-top: 1%;
  font-size: 16px;
  color: black;
}
.span_cls{
  vertical-align: text-bottom;
}
// #downshift-1-menu,.sui-search-box_autocomplete-container{
  // max-height: 5%;
  // height: 5%;
  // max-height: 20px;
  // height: 20px;
// }
.homeSearch_icon{
  position: relative;
    margin-left: -33px;
    color: white;
    vertical-align: middle;
    margin-bottom: 4px;
    position: relative;
    font-size: 22px;
    cursor: pointer;
}
.sui-search-box__text-input{
  border-radius: 4px 0px 0px 4px !important;
  border: none !important;
}
.translated-msg{  
  font-weight: 600;  
  color: rgb(169 30 30);  
  font-size: 15px;
}
.translated-msg-lower{   
  font-weight: 600;   
  color: rgb(240, 44, 44);
  font-size: 14px;
}