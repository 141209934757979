@import "styles//varibles.scss";

.home_page_main {
  .homePage {
    padding: 32px 24px;
    // background-color: $appheaderbgClr;
    .appHeader {
      .header2 {
        left: 24px;
      }
    }
  }
  .homeContainer .img {
    top: auto;
    left: 0;
    z-index: 0;
    background-size: 100% 80%;
    opacity: 1;
    width: 100%;
    height: 385px;
    overflow-y: hidden;
  }
  .homeheader3 {
    background-color: $homeheaderBg3;
    color: $clrWhite;
    margin-left: 0;
    margin-right: 0;
  }
  .homeheaderdiv {
    padding: 11px;
    font-size: 25px;
    p {
      font-size: 10px;
      text-align: right;
    }
  }
  .bottomRow {
    padding: 3%;
  }
  
  .buttonCls {
    margin-top: -17%;
  }
  
  .searchbox_div {
    width: 60%;
    margin-top: 7px;
  }
  
  .sui-search-box {
    display: flex;
    position: relative;
    /* justify-content: center; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif;
  }

  .sui-layout-body__inner {
    // max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }
  .sui-layout-main {
    width: 100%;
    padding: 20px 0 32px 0px !important;
  }
  .sui-layout-sidebar {
    // width: 0%;
    padding: 0;
    width: 30%;
  }  
  .sui-layout-body {
    margin-top: 30px;
  }
}
