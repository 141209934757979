@import "styles/varibles.scss";

.sui-result__title {
  color: $titlecolor;
}

.sui-result em:after {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  background: $heightllight_clr;
  pointer-events: none;
}

.sui-layout-header {
  background-color: $appheaderbgClr;
  padding: 0;
  border-bottom: 1px solid $layout_bottomp;
}
.css-df17o1 {
  position: fixed !important;
  color: $loader_clr !important;
  background: $loader_background !important;
}
.css-50etie svg circle {
  stroke: $loader_clr !important;
}
.sui-layout-body__inner{
  // min-height: 768px;
  max-width: 100% !important;
  margin-left: 24px;
}

.sui-layout-main {
  width: 100%;
  padding: 20px 0 32px 0px !important;
}
.sui-search-box__submit {
    background-color: $home_seconddiv_heading;
    color: transparent;
    // background: unset;
    background: linear-gradient($home_seconddiv_heading, $home_seconddiv_heading) $home_seconddiv_heading;
    padding: 3px;
    margin-left: 0;
    border-radius: 0px 10px 10px 0px;
}