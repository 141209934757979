@import "styles/varibles.scss";


.main_div_box{
    
    margin-bottom: 10px;
    font-size: 16px;
}
.main_continer{
    height: 100%;
    background-color: $pure_white;
    // margin-left: 3%;
    padding: 20px 56px 0px;
}